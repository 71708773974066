<template>
  <div id="app">
    <router-view />
  </div>
</template>


<script>
import { getLoginUserInfo } from "@/api/user.js";

export default {
  name: 'App',
  mounted() {
    getLoginUserInfo().then(res => {
      if (res.data.code === 200) {
        const data = res.data.data;
        console.log('data.user_info========', data.user_info);
        localStorage.setItem('pf_id', data.user_info.platform_id)
      }
    })
  },
}
</script>


<style lang="scss">
body {
  background: #F3F6FB;
  background-color: #F3F6FB;
}

* {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
</style>
