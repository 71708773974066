import axios from 'axios'
// import { Toast, Dialog } from 'vant'


const Authorization = sessionStorage.getItem('Authorization')
axios.defaults.headers.common['Authorization'] = Authorization

import config from '@/config/platform'
const { BASE_URL } = config

// 创建一个axios实例
const service = axios.create({
	baseURL: `${BASE_URL}/api/`, //服务器生产地址
	// baseURL: 'https://developapi.weizu888.com/api/', //服务器生产地址
	// baseURL: 'http://xyapi.com/api/', //服务器生产地址
	timeout: 100000
})


// 请求拦截器
service.interceptors.request.use(
	config => {
		// 这里做些发送请求前的事情
		return config
	},
	error => {
		console.log(error)
		return Promise.reject(error)
	}
)


export default service
